<template>
  <div>
    <div class="title">合同管理</div>
    <el-form class="flex-row" ref="form" :model="form" style="width: 1050px">
      <el-form-item class="flex-row" label="合同编号：">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item class="flex-row" label="供应商：" style="margin:0 50px 22px">
        <el-select v-model="form.region" placeholder="请选择供应商">
          <el-option label="供应商1" value="shanghai"></el-option>
          <el-option label="供应商2" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-button class="btn-black" style="margin-bottom: 22px;">搜索</el-button>
      <el-button type="info" style="margin:0 20px  22px 100px">同步数据</el-button>
    </el-form>
    <el-table :data="tableData" style="width: 1100px">
      <el-table-column prop="id" label="合同编号" align="center" width="130"></el-table-column>
      <el-table-column prop="name" label="采购商" align="center" width="150"></el-table-column>
      <el-table-column prop="price" label="合同金额（万美元）" align="center" width="200"></el-table-column>
      <el-table-column prop="date" label="交付日期" align="center" width="150"></el-table-column>
      <el-table-column prop="type2" label="交付状态" align="center" width="150"></el-table-column>
      <el-table-column prop="type1" label="合同状态" align="center" width="120"></el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="170">
        <template v-slot="scope">
          <el-button size="small" class="btn-black" @click="handleDelete(scope.$index, scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: ''
      },
      tableData: [{
        id: 'LT000239',
        name: '红星集团',
        price: '1000',
        type1: '已签约',
        type2: '生产中',
        date: '2020-04-27',
        zip: 1000037
      }, {
        id: 'LT000343',
        name: '摩恩电气股份',
        price: '500',
        type1: '已签约',
        type2: '生产中',
        date: '2020-05-02',
        zip: 1000089
      }, {
        id: 'LT000366',
        name: '海蒂诗  Hettich',
        price: '805',
        type1: '已签约',
        type2: '生产中',
        date: '2020-06-08',
        zip: 1000249
      }, {
        id: 'LT000399',
        name: '柯诺Krono木业集团',
        price: '20,000',
        type1: '已签约',
        type2: '未生产',
        date: '2020-08-19',
        zip: 10406049
      }]
    };
  },
  onLoad() { },
  methods: {
    handleDelete(index, rows) {
      rows.splice(index, 1);
    }
  }
};
</script>

<style lang="scss">
.title {
  padding: 2px 10px;
  border-left: 6px solid #81d8d0;
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
}
.el-table th {
  background: #81d8d0;
  color: white;
}
.btn-black {
  background: #81D8D0;
  border-color: #81D8D0;
  color: white;
}
.btn-black:focus {
  background: #81D8D0;
  border-color: #81D8D0;
  color: #fff;
}
.btn-black:hover {
  color: white;
  background: #ef6c00;
  border-color: #ef6c00;
}
</style>
